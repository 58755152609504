<template>
  <div
    class="sidebar"
    :data-color="sidebarItemColor"
    :data-image="sidebarBackgroundImage"
    :style="sidebarStyle"
  >
    <div class="logo">
      <a href="#" class="simple-text logo-mini">
        <div class="logo-img">
          <img :src="imgLogo" alt="" style="width: 41px;"/>
        </div>
      </a>

      <a
        class="simple-text logo-normal"
      >
        {{ $t('title.name') }}
      </a>
    </div>
    <div class="sidebar-wrapper">
      <slot name="content"></slot>
      <md-list class="nav">
        <!--By default vue-router adds an active class to each route link. This way the links are colored when clicked-->
        <slot>
          <sidebar-link
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :to="link.path"
            :link="link"
          >
          </sidebar-link>
        </slot>
      </md-list>
    </div>
  </div>
</template>
<script>
import SidebarLink from "./SidebarLink.vue";
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';

export default {
  components: {
    SidebarLink,
  },
  props: {
    title: {
      type: String,
      default: "Tech Care Warehouse",
    },
    sidebarBackgroundImage: {
      type: String,
      default: require("@/assets/img/sidebar-2.jpg"),
    },
    imgLogo: {
      type: String,
      default: require("@/assets/img/tech-care.png"),
    },
    sidebarItemColor: {
      type: String,
      default: "green",
      validator: (value) => {
        let acceptedValues = ["", "purple", "blue", "green", "orange", "red"];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const locale = ref(localStorage.getItem("lang"));
    const sidebarWidth = ref(260);
    const viewportWidth = ref(window.innerWidth);

    const updateViewportWidth = () => {
      viewportWidth.value = window.innerWidth;
    };

    onMounted(() => {
      window.addEventListener('resize', updateViewportWidth);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateViewportWidth);
    });

    const sidebarStyle = computed(() => {
      const sidebarOffsetX = locale.value === 'en' ? 0 : viewportWidth.value - sidebarWidth.value;
      return {
        backgroundImage: `url(${require('@/assets/img/sidebar-2.jpg')})`,
        transform: `translateX(${sidebarOffsetX}px)`
      };
    });

    return {
      locale,
      sidebarWidth,
      sidebarStyle
    };
  }
};
</script>
<style>
@media screen and (min-width: 991px) {
  .nav-mobile-menu {
    display: none;
  }
}
</style>
