<template>
    <div id="app" class="container">
        <v-row>
            <v-col cols="12">
                <v-data-table :headers="translatedHeaders" :items="translatedItems" width="auto">
                    <template v-slot:header="{ props }">
                        <thead>
                            <tr>
                                <th v-for="header in props.headers" :key="header.text" :colspan="header.colspan">
                                    <v-text-field v-if="header.text !== 'Date' && header.text !== 'التاريخ'"
                                        v-model="header.inputValue" hide-details></v-text-field>
                                </th>
                            </tr>
                            <tr>
                            </tr>
                        </thead>
                    </template>
                    <template #item="{ item }">
                        <tr>
                            <td>{{ item.warehouseName }}</td>
                            <td v-if="item.type == 'tran'">Transfer</td>
                            <td v-else-if="item.type == 'exp'">Exportation</td>
                            <td v-else-if="item.type == 'inq'">Refill</td>
                            <td>{{ item.userName }}</td>
                            <td>{{ item.date }}</td>
                            <td>
                                <v-icon :data-item="item.type" small @click="showItem(item)">mdi-eye</v-icon>
                            </td>

                        </tr>
                    </template>

                </v-data-table>
            </v-col>
        </v-row>

    </div>
</template>

<script>
import { useOperationsStore } from "./../../store/OperationsStore";
export default {
    name: "DataTable",
    props: {

        edit: {
            type: Boolean,
        },
        item: {
            type: Object,
        },
        headers: {
            type: Array,
        },
    },
    data() {
        return {
            operationsStore: null,
        };
    },
    methods: {
        showItem(item) {
            this.$emit("item", item);
            this.$emit("edit", true);
            this.operationsStore.setOperationItems(item.id)
        }
    },
    computed: {
        getOperations() {
            return this.operationsStore.getOperations;
        },
        translatedHeaders() {
            return this.$t(`operationsHeaders`);
        },
        translatedItems() {
            return this.getOperations.filter((item) => {
                const warehouseName = item.warehouseName;
                let type = item.type
                if (type === 'exp') {
                    type = 'Exportation'
                }else if(type == 'inq'){
                    type = 'Refill'
                }else{
                    type = 'Transfer'
                }
                const user = item.userName
                return (
                warehouseName.toLowerCase().includes(this.translatedHeaders[0].inputValue.toLowerCase()) &&
                type.toLowerCase().includes(this.translatedHeaders[1].inputValue.toLowerCase()) &&
                user.toLowerCase().includes(this.translatedHeaders[2].inputValue.toLowerCase()) 
                );
            });
        },
    },
    created() {
        this.operationsStore = useOperationsStore();
        this.operationsStore.setOperations()
    },
};
</script>

<style scoped></style>