import axios from 'axios';
import Vue from 'vue';
const token = localStorage.getItem('token')
const http2 = axios.create({
  baseURL: 'https://auth.techcare-sy.com/api/', // Replace with your desired base URL
  headers: { 'Content-Type': 'application/json' ,'Authorization': `Bearer ${token}`},
  
});

http2.interceptors.response.use(
  (response) => {
    if ((response.config.method == "post" || response.config.method == "put" || response.config.method == "delete")) {
      if (((response.status == 200) || (response.status == 201))) {
        Vue.prototype.$toast.success("Success")
      } else if(response.status == 403) {
        Vue.prototype.$toast.error("Unauthorized")
        // window.location.href = '/login';


      }else{
        Vue.prototype.$toast.error("Error")
      }
    }
    return response;
  },
  (error) => {
    if(error.response){
      Vue.prototype.$toast.error(error.response.data.error)
    }else{
      Vue.prototype.$toast.error("Unexpected Error")
    }
    // return Promise.reject(error);
  }
);

export default http2;