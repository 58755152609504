<template>
  <div :dir="`${locale =='en' ?'ltr':'rtl'}`">
    <v-app>
    
    <router-view></router-view>
 
</v-app>
  </div>
 

</template>

<script>
export default {
  name: "App",
  data(){
   return {
    locale:localStorage.getItem('lang')
   }
  }
};
</script>
