<template>
    <div id="app" class="container">
        <v-row>
            <v-col cols="12">
                <v-data-table :headers="headers" :items="translatedItems" width="auto">
                    <template v-slot:header="{ props }">
                        <thead>
                            <tr>
                                <th v-for="header in props.headers" :key="header.text" :colspan="header.colspan">
                                    <v-text-field
                                        v-if="header.text !== 'Cost' && header.text !== 'الكلفة' && header.text !== 'Min Qty' && header.text !== 'الكمية الدنيا'"
                                        v-model="header.inputValue" hide-details></v-text-field>
                                </th>
                            </tr>
                            <tr>

                            </tr>
                        </thead>
                    </template>
                    <template #item="{ item }">
                        <tr>
                            <td>{{ item.name }}</td>
                            <td>{{ item.itemNo }}</td>
                            <td>{{ item.factoryName }}</td>
                            <td>{{ item.model }}</td>
                            <td>{{ item.source }}</td>
                            <td>{{ item.cost }}</td>
                            <td>{{ item.minQty }}</td>
                            <td>
                                <v-icon small @click="editItem(item)">mdi-pencil</v-icon>
                            </td>
                            <td>
                                <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
                            </td>
                        </tr>
                    </template> 
                </v-data-table>
                <template>
          <div id="app" class="container">
            <v-dialog :value="messageDialog" @input="$emit('input')" max-width="590" height="632" persistent>
              <v-card class="rounded-lg">
                <v-toolbar flat color="primary" class="rounded-t-lg mb-4">
                  <div>
                    <v-toolbar-title class="text-h6 white--text">{{ $t('labels.Are_You_Sure') }}</v-toolbar-title>
                  </div>

                  <v-spacer></v-spacer>
                  <v-btn icon @click="close()" class="white--text">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>

                <v-card-text class="d-flex align-center">
                  <span class="ml-6" style="font-size: 1rem;">{{ $t('labels.item_will_removed') }}</span>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <div>
                    <v-btn color="blue darken-1" text @click="submitDelete()">{{ $t('buttons.submit') }}</v-btn>
                  </div>

                </v-card-actions>

              </v-card>
            </v-dialog>
          </div>
        </template>
            </v-col>
        </v-row>

    </div>
</template>

<script>
import { useItemsStore } from "./../../store/ItemsStore";
export default {
    name: "DataTable",
    props: {

        edit: {
            type: Boolean,
        },
        item: {
            type: Object,
        },
        headers: {
            type: Array,
        },
    },
    data() {
        return {
            itemsStore: null,
            messageDialog: false,
            tempItem: {},
        };
    },
    methods: {
        close(){
            this.messageDialog = false
        },
        editItem(item) {
            this.$emit("item", item);
            this.$emit("edit", true);

        },
        deleteItem(item) {
            this.messageDialog = true;
            this.tempItem = item;
        },
        submitDelete() {
            this.itemsStore.deleteItem(this.tempItem.id);
            this.messageDialog = false
        }
    },
    computed: {
        getItems() {
            return this.itemsStore.getItems;
        },
        translatedHeaders() {
            return this.$t(`itemsHeaders`);
        },
        translatedItems() {
            return this.getItems.filter((item) => {
                const name = item.name;
                const itemNo = item.itemNo
                const factory = item.factoryName
                const model = item.model
                const source = item.source
                return (

                    name.toLowerCase().includes(this.translatedHeaders[0].inputValue.toLowerCase()) &&
                    itemNo.toLowerCase().includes(this.translatedHeaders[1].inputValue.toLowerCase()) &&
                    factory.toLowerCase().includes(this.translatedHeaders[2].inputValue.toLowerCase()) &&
                    model.toLowerCase().includes(this.translatedHeaders[3].inputValue.toLowerCase()) &&
                    source
                        .toLowerCase().includes(this.translatedHeaders[4].inputValue.toLowerCase())

                );
            });
        },
    },
    mounted(){
        this.itemsStore.setItems()
    },
    created() {
        this.itemsStore = useItemsStore();
    },
};
</script>

<style scoped></style>