import { defineStore } from "pinia";
import http2 from "../http2";
export const useBranchesStore = defineStore("branchesStore", {
    state: () => ({ 
      branches:[]
     }),
    getters: {
      getBranches(){
           return this.branches
      }
    },
    actions: {
     async setBranches() {
         await http2.get("branches").then((response) => {
              
              this.branches = response.data
            });
      },
    },
  });