<template>
    <div id="app" class="container">

        <v-dialog :value="dialogImport" @input="$emit('input')" max-width="590" height="632">
            <v-card class="rounded-lg">
                <v-toolbar flat color="primary" class="rounded-t-lg">
                    <div>
                        <v-toolbar-title class="text-h6 white--text">{{ $t('labels.importExcel') }}</v-toolbar-title>

                    </div>

                    <v-spacer></v-spacer>
                    <v-btn icon @click="close()" class="white--text">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-spacer></v-spacer>

                <div class="align-items-center">
                    <div>

                        <v-card-text>
                            <v-file-input @change="handleFileUpload" counter multiple show-size
                                truncate-length="1"></v-file-input>
                        </v-card-text>

                    </div>


                </div>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div>
                        <v-btn color="blue darken-1" text @click="submitImport">{{ $t('buttons.submit') }}</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>


</template>

<script>
import { useItemsStore } from "./../../store/ItemsStore";

export default {
    props: {
        dialogImport: {
            type: Boolean,
        }
    },

    data() {
        return {
            excelFile: null,
            itemStore:null
        };
    },
    methods: {
        handleFileUpload(event) {
            this.excelFile = event;
        },
        submitImport(){
             this.itemStore.importExcel(this.excelFile);
             this.$emit("input", false);
        },
        close() {
            this.$emit("input", false);
            this.$emit("edit", false);
        },
    },
    created() {
        this.itemStore = useItemsStore();
    },
};
</script>

<style></style>