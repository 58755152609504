<template>
  <div id="app" class="container">
    <v-tab-item>
      <v-card-text v-if="item.type == 'tran'">
        <v-text-field :value="item.targetWarehouse" disabled :label="$t('labels.targetWarehouse')"></v-text-field>
      </v-card-text>
      <v-card-text v-if="item.exType != null">
        <v-text-field :value="item.exType" disabled :label="$t('labels.exportationType')"></v-text-field>
      </v-card-text>

      <v-card-text v-if="item.seller != null">
        <v-text-field :value="item.seller" disabled :label="$t('exportationTypes.seller')"></v-text-field>
      </v-card-text>

      <v-card-text v-if="item.buyer != null">
        <v-text-field :value="item.buyer" disabled :label="$t('exportationTypes.buyer')"></v-text-field>
      </v-card-text>

      <v-card-text v-if="item.note != null">
        <v-textarea :value="item.note" disabled :label="$t('exportationTypes.note')"></v-textarea>
      </v-card-text>

      <v-card-text>
        <tab-data-table :headers="$t('operationTabHeaders')"></tab-data-table>
      </v-card-text>

    </v-tab-item>
  </div>

</template>

<script>
import TabDataTable from './TabComponents/TabDataTable.vue'
export default {
  name: 'TransferTab',
  components:{
    TabDataTable
  },
  props: {
    item: {
      type: Object,
    },
 
  },
  data(){
    return{
      headers: [
                { text: "Item Name", value: "itemName" },
                { text: "ItemNo", value: "itemNo" },
                { text: "Qty", value: "qty" },
            ],
    }
  },
  

}
</script>

<style></style>