import { defineStore } from "pinia";
import http from "../http";

export const useFactoriesStore = defineStore("factoriesStore", {
  state: () => ({ 
    factories:[]
   }),
  getters: {
    getFactories(){
         return this.factories
    }
  },
  actions: {
   async setFactories() {
       await http.get("factories").then((response) => {
        if (response != undefined) {
          this.factories = response.data.data
          
        }
          
        
          });
    },
  },
});
