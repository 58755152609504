// =========================================================
// * Vue Material Dashboard - v1.5.2
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard
// * Copyright 2024 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/vue-material-dashboard/blob/master/LICENSE.md)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App";
// router setup
import routes from "./routes/routes";

// Plugins
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import Notifications from "./components/NotificationPlugin";

// MaterialDashboard plugin
import MaterialDashboard from "./material-dashboard";

import Chartist from "chartist";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/dist/vuetify.css";
import Vuetify from "vuetify";
import i18n from "./i18n";
import axios from "axios";
import http from "./http";
import { createPinia, PiniaVuePlugin } from "pinia";
import Toast from 'vue-toastification';
import "vue-toastification/dist/index.css";
import '@fortawesome/fontawesome-free/css/all.css';
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
const locale = localStorage.getItem('lang');
if (!locale) {
  localStorage.setItem('lang','en')
}

document.documentElement.style.setProperty('--lang', localStorage.getItem('lang') || 'en');
// configure router
// const elements = document.getElementsByClassName('main-panel');

// if (locale == 'ar') {
//   elements.setAttribute('float', 'left');
// }else{
//   elements.setAttribute('float', 'right');

// }
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: "nav-item active",
});
router.beforeEach((to, from, next) => {

  if(to.path != '/login') {
    const isAuthenticated = (localStorage.getItem('token'))? true :false
    const abilities = (localStorage.getItem('abilities'))? localStorage.getItem('abilities') :[]
    const isAuthorized = abilities.includes('admin') || abilities.includes('warehouse-management')
    if (to.matched.some((route) => route.meta.requiresAuth) && (!isAuthenticated || !isAuthorized)) {
      // If the route requires authentication and the user is not authenticated, redirect to the login page
      next('login')
      //window.location.href = '/login'
    } else {
      // Continue to the next route+
      next()
    }
  } else {
      // // This is where it should have been
      next()
  }

  
});
// router.beforeEach((to, from) => {
//   // ...
//   // explicitly return false to cancel the navigation
//   if (to.name !== 'login' && !(localStorage.getItem('token'))) this.$router.push('/login');
//   else return this.$router.push('/dashboard')
// })

Vue.prototype.$Chartist = Chartist;
Vue.prototype.$toast = Toast;

Vue.use(VueRouter);
Vue.use(MaterialDashboard);
Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
Vue.use(Notifications);
Vue.use(Vuetify);
Vue.use(Toast)
/* eslint-disable no-new */
new Vue({
  el: "#app",
  i18n,
  render: (h) => h(App),
  router,
  vuetify,
  pinia,
  Toast,
  data: {
    Chartist: Chartist,
  },
});
