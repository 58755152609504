import en from "./assets/i18n/en.js";
import ar from "./assets/i18n/ar.js";
import VueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(VueI18n);

export default new VueI18n({
  locale: localStorage.getItem("lang") || "en",
  messages: {
    en: en,
    ar: ar,
  },
  direction: {
    ar: 'rtl'
  }
});
