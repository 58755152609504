<template>
  <div id="app" class="container">

    <v-dialog :value="dialog" @input="$emit('input')" max-width="590" height="632">
      <v-card class="rounded-lg">
        <v-toolbar flat color="primary" class="rounded-t-lg">

          <v-toolbar-title class="text-h6 white--text">{{ $t('labels.operationDetails') }}</v-toolbar-title>



          <v-spacer></v-spacer>
          <v-btn icon @click="close()" class="white--text">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-spacer></v-spacer>

        <div class="align-items-center">
          <div>







            <v-toolbar color="cyan" dark flat>




              <v-spacer></v-spacer>
              <template v-slot:extension>
                <v-tabs v-model="tab" align-with-title>
                  <v-tabs-slider color="yellow"></v-tabs-slider>

                  <v-tab>
                    {{ $t('tabs.mainData') }}
                  </v-tab>
                  <v-tab>
                    {{ $t('tabs.moreData') }}
                  </v-tab>
                </v-tabs>
              </template>
            </v-toolbar>

            <v-tabs-items v-model="tab">
              <!-- Main Data Tab  -->
              <v-tab-item>
                <v-card-text>
                  <v-text-field :value="item.warehouseName" disabled :label="$t('labels.warehouseName')"></v-text-field>
                </v-card-text>

                <v-card-text>
                  <v-text-field :value="computedValue" disabled :label="$t('labels.type')"></v-text-field>
                </v-card-text>

                <v-card-text>
                  <v-text-field :value="item.userName" disabled :label="$t('labels.user')"></v-text-field>
                </v-card-text>

                <v-card-text>
                  <v-text-field :value="item.date" disabled :label="$t('labels.date')"></v-text-field>
                </v-card-text>
              </v-tab-item>

              <!-- More Data Tab  -->

              <v-row>
                <v-col>
                  <transfer-tab :item="item"></transfer-tab>
                </v-col>
              </v-row>



            </v-tabs-items>






          </div>


        </div>


      </v-card>
    </v-dialog>
  </div>


</template>

<script>
import { useFactoriesStore } from "./../../store/FactoriesStore";
import { useItemsStore } from "./../../store/ItemsStore";
import TransferTab from "./../../components/OperationsComponents/TransferTab.vue";
export default {
  components: {
    TransferTab,
  },
  props: {
    dialog: {
      type: Boolean,
    },
    item: {
      type: Object,
    },
    edit: {
      type: Boolean,
    },
  },

  data() {
    return {
      name: "",
      itemNo: "",
      factory: "",
      model: "",
      source: "",
      cost: "",
      minQty: "",
      factoryStore: null,
      itemStore: null,
      tab: null,
    };
  },
  methods: {
    submitAdd() {
      const payload = {
        name: this.name,
        item_no: this.itemNo,
        factory_id: this.factory,
        model: this.model,
        source: this.source,
        cost: this.cost,
        min_qty: this.minQty,
      };
      this.itemStore.createItem(payload);
      this.$emit("input", false);
    },
    submitEdit(item) {
      const payload = {
        name: this.name,
        item_no: this.itemNo,
        factory_id: this.factory,
        model: this.model,
        source: this.source,
        cost: this.cost,
        min_qty: this.minQty,
      };
      this.itemStore.editItem(item.id, payload);
      this.$emit("input", false);
    },
    close() {
      this.$emit("input", false);
      this.$emit("edit", false);
    },
  },
  watch: {
    edit(val) {
      if (!val) {
        this.name = "";
        this.itemNo = "";
        this.factory = "";
        this.model = "";
        this.source = "";
        this.cost = "";
        this.minQty = "";
      }
    },
    item(val) {
      if (val) {
        this.name = val.name;
        this.itemNo = val.itemNo;
        this.factory = val.factoryId;
        this.model = val.model;
        this.source = val.source;
        this.cost = val.cost;
        this.minQty = val.minQty;
      }
    },
  },
  computed: {
    getFactories() {
      return this.factoryStore.getFactories;
    },
    computedValue() {
      if (this.item.type == "tran") {
        return "Transfer";
      } else if (this.item.type == "inq") {
        return "Refill";
      } else if (this.item.type == "exp") {
        return "Exportation";
      }
      return "";
    },
  },
  // created() {
  //   this.factoryStore = useFactoriesStore();
  //   this.itemStore = useItemsStore();
  // },
};
</script>

<style></style>