import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";

import Dashboard from "@/pages/Dashboard.vue";
// import Users from "@/pages/Users.vue";
import Operations from "@/pages/Operations.vue";
// import Typography from "@/pages/Typography.vue";
import Warehouse from "@/pages/Warehouse.vue";
import WarehouseItems from "@/pages/WarehouseItems.vue";
import Notifications from "@/pages/Notifications.vue";
import Items from "@/pages/Items.vue";
import Login from "@/pages/Login/Login.vue";

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta:{
          hideFooter: true,
          requiresAuth: true
        }
      },
      // {
      //   path: "users",
      //   name: "Users",
      //   component: Users,
      //   meta: {
      //     hideFooter: true,
      //     requiresAuth: true
      //   },
      // },
      // {
      //   path: "table",
      //   name: "Table List",
      //   component: TableList,
      // },
      
      {
        path: "warehouse",
        name: "Warehouses",
        meta: {
          hideFooter: true,
          requiresAuth: true
        },
        component: Warehouse,
      },
      {
        path: "warehouse-items",
        name: "WarehouseItems",
        meta: {
          hideFooter: true,
          requiresAuth: true
        },
        component: WarehouseItems,
      },
      // {
      //   path: "reports",
      //   name: "Reports",
      //   component: Dashboard,
      //   meta:{
      //     hideFooter: true,
      //     requiresAuth: true
      //   },
      // },
      {
        path: "items",
        name: "Items",
        component: Items,
        meta: {
          hideFooter: true,
          requiresAuth: true
        },
      },
      {
        path: "operations",
        name: "Operations",
        component: Operations,
        meta: {
          hideFooter: true,
          requiresAuth: true
        },
        
      },
    ],
  },
 
];

export default routes;
