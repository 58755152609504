import { defineStore } from "pinia";
import http from "../http";

export const useOperationsStore = defineStore("operationsStore", {
  state: () => ({ 
    operations:[],
    operationItems:[]
   }),
  getters: {
    getOperations(){
         return this.operations
    },
    getOperationItems(){
        return this.operationItems
    }
  },
  actions: {
   async setOperations() {
       await http.get("operations").then((response) => {
        if (response!=undefined) {
          this.operations = response.data.data
        }
          });
    },
    async setOperationItems(operationId) {
      await http.get(`operations/operation-items/${operationId}`).then((response) => {
           let data = response.data.data.items
           this.operationItems = data
         });
   },
  },
});
