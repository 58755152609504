<template>
    <div id="app" class="container">
        <v-row>
            <v-col cols="12">
                <v-data-table :headers="headers" :items="getOperationItems" width="auto">

                    <template #item="{ item }">
                        <tr>
                            <td>{{ item.itemName }}</td>
                            <td>{{ item.itemNo }}</td>
                            <td>{{ item.qty }}</td>
                        </tr>
                    </template>
                    <!-- Define custom filter inputs for specific columns -->
                   
                </v-data-table>
            </v-col>
        </v-row>

    </div>
</template>

<script>
import { useOperationsStore } from "./../../../store/OperationsStore.js";
export default {
    name: "TabDataTable",
    props: {
        item: {
            type: Object,
        },
        headers: {
            type: Array,
        },
    },
    data() {
        return {
            operationsStore: null,
        };
    },
    methods: {

    },
    computed: {
        getOperationItems() {
            return this.operationsStore.getOperationItems;
        }
    },
    created() {
        this.operationsStore = useOperationsStore();
        // this.operationsStore.setOperations()
    },
};
</script>

<style scoped></style>