<template>
    <div id="app" class="container">
        <v-row>
            <v-col cols="12">
                <data-table   :edit="edit" @edit="handleEdit" @item="handleItem" :headers="$t('itemsHeaders')">
                </data-table>
            </v-col>
            <v-btn class="item-btn" @click="openDialog">{{ $t('buttons.addNew') }}</v-btn>
            <v-btn class="item-btn" @click="dialogImport = true">{{ $t('buttons.importExcel') }}</v-btn>
            
            
            <dialog-component v-model="dialog" :dialog="dialog" :item="item" :edit="edit" @update:edit="edit = $event"></dialog-component>
            <dialog-import-component v-model="dialogImport" :dialogImport="dialogImport"></dialog-import-component>
            
        </v-row>

    </div>
</template>

<script>
import DataTable from "../components/ItemsComponents/DataTable.vue";
import DialogComponent from "../components/ItemsComponents/DialogComponent.vue";
import DialogImportComponent from "../components/ItemsComponents/DialogImportComponent.vue";

import { useWarehouseStore } from "./../store/WarehouseStore";
// import { useBranchesStore } from "./../store/BranchesStore";
export default {
    components: {
        DataTable,
        DialogComponent,
        DialogImportComponent
    },
    data() {
        return {
            warehouseStore: null,
            branchStore: null,
            dialog: false,
            edit: false,
            item:{},
            dialogImport:false
        }
    },
    mounted(){
        this.warehouseStore.setWarehouses();
    },
    created() {
        this.warehouseStore = useWarehouseStore();
        // this.branchStore = useBranchesStore();
        // this.branchStore.setBranches();
    },
    methods: {
        openDialog() {
            this.dialog = true
            this.edit = false
        },
        handleEdit(val){
          this.dialog = val
          this.edit = val
        },
        handleItem(val){
           this.item = val
        }
    },
    computed: {}
}
</script>

<style scoped>
.item-btn {
    background-color: #2a8862 !important;
    color: white !important;
    margin-top: 46px;
    /* Adjust margin top as needed */
    margin-left: 20px;
    /* Adjust margin left as needed */
    height: 44px;
    border-radius: 10px;
    width: 145px;
    margin-right: 10px;
}

.items-btn {
    background-color: white !important;
    background: #000000;
    margin-top: 46px;
    /* Adjust margin top as needed */
    margin-left: 20px;
    /* Adjust margin left as needed */
    height: 44px;
    border-radius: 10px;
    width: 145px;
    margin-right: 10px;
    border: 2px solid #2A8862
}
</style>