import { defineStore } from "pinia";
import http from "../http";
import Warehouse from "../pages/Warehouse.vue";

export const useReportsStore = defineStore("reportsStore", {
    state: () => ({
        orderedWarehouseItems: []
    }),
    getters: {
        getFactories() {
            return this.orderedWarehouseItems
        }
    },
    actions: {
        async setOrderedWarehouseItems(warehouse_id) {
            const payload = {
                warehouse_id: warehouse_id
            }
            await http.get("items/operations/ordered-by-qty", payload).then((response) => {
                this.orderedWarehouseItems = response.data.data
            });
        },
    },
});
