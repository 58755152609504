import { defineStore } from "pinia";
import http from "../http";

export const useAuthStore = defineStore("authStore", {
  state: () => ({
    userCreditianls: {
      email: '',
      paswword: '',
      token: ''
    }
  }),
  getters: {
    getToken() {
      return this.userCreditianls.token
    }
  },
  actions: {
     login(payload) {
       http.post("auth/login", payload).then((response) => {        
        // localStorage.setItem('abilities', response.data.abilities);
        //
        //
        // window.location.href = '/';

      });
    },
    setToken(token) {
      this.userCreditianls.token = token
    }

  },
});
