<template>
  <div class="linear-background">
    <div class="container-fluid">
      <div class="row">
        <div class="col-6 center login-logo">
          <img src="../../assets/img/white-logo.png" alt="Logo" />
        </div>
        <div class="col-6 login-col">
          <form class="d-flex align-items-center justify-content-center flex-column login-form w-100" @submit.prevent="submitLogin">
            <h1 class="col-5"></h1>
            
            <!-- Email Input -->
            <div class="center my-2 w-100" style="position: relative;">
              <input v-model="form.email" class="my-2" type="email" placeholder="  Email" required />
            </div>

            <!-- Password Input -->
            <div class="center my-2 w-100" style="position: relative;">
              <input v-model="form.password" class="login-password" :type="showPassword ? 'text' : 'password'"
                placeholder="  Password" minlength="6" required />
              <i :class="showPassword ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash'" @click="togglePasswordVisibility"></i>
            </div>

            <!-- Error Message -->
            <div v-if="errorMessage" class="alert alert-danger w-100 my-2" role="alert">
              {{ errorMessage }}
            </div>

            <!-- Submit Button -->
            <div class="center my-2 w-100" style="position: relative;">
              <button :disabled="loading" class="mt-4">
                <span v-if="loading">Signing In...</span>
                <span v-else>Sign In</span>
              </button>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http2 from '../../http2';
import { useAuthStore } from "../../store/AuthStore";

export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      showPassword: false,
      errorMessage: null, // Store the error message here
      loading: false, // For loading state of the button
      authStore: null,
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    submitLogin() {
      // Reset error message and set loading to true
      this.errorMessage = null;
      this.loading = true;

      http2.post('login', this.form)
        .then(response => {
          const token = response.data.token;
          const abilities = response.data.abilities; // Uncomment if you plan to use abilities

          // Store token and abilities in localStorage
          localStorage.setItem('token', token);
          localStorage.setItem('abilities', JSON.stringify(abilities));

          // Set the Authorization header globally for axios
          http2.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          
          // Call the authStore login method
          this.authStore.login(this.form);

          // Redirect to the home page
          window.location.href = '/';
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            this.errorMessage = error.response.data.message || 'Invalid credentials';
          } else {
            this.errorMessage = 'An unexpected error occurred. Please try again.';
          }
        })
        .finally(() => {
          this.loading = false; // Disable loading state
          // this.form.password = ''; // Clear the password field
        });
    }
  },
  created() {
    this.authStore = useAuthStore();
  }
};
</script>

<style>
@import "../../assets/scss/style.css";

/* Optional UI enhancement for the error message */
.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  padding: 10px;
  border-radius: 4px;
}
</style>
